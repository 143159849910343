import React from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  VirtualizedList,
} from 'react-native';
import Icon from 'shared/ui/Icon';
import { circleRadius, rs } from 'shared/lib/mixins';
import Avatar from 'shared/ui/Avatar';
import useStories from './hooks/useStories';
import { FONT_REGULAR } from 'shared/lib/typography';
import Logo from 'shared/assets/icons/Feed/logo.png';

const Stories = () => {
  //const stories = useStories();
  return (
    <ScrollView horizontal style={styles.root}>
      <View style={styles.addStory}>
        <Icon name='plus' width={rs(12)} height={rs(12)} />
      </View>
      {/*{stories?.map((item) => (*/}
      {/*  <View style={styles.user} key={item.username}>*/}
      {/*    <Image source={{ uri: item.imageUrl }} style={styles.image} />*/}
      {/*    <Text style={styles.username}>@{item?.username}</Text>*/}
      {/*  </View>*/}
      {/*))}*/}
      {[0].map((item) => (
        <View style={styles.user} key={'@Скоро!'}>
          <Image source={Logo} style={styles.image} />
          <Text style={styles.username}>@Скоро!</Text>
        </View>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  root: {
    marginHorizontal: rs(15),
    marginVertical: rs(21),
  },
  addStory: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: rs(14),
    borderColor: '#9797BD',
    borderRadius: circleRadius(),
    borderWidth: rs(1),
    width: rs(45),
    height: rs(45),
  },
  user: {
    marginHorizontal: rs(15),
    alignItems: 'center',
  },
  image: {
    width: rs(45),
    height: rs(45),
    //borderRadius: circleRadius(),
  },
  username: {
    textAlign: 'center',
    color: '#9797BD',
    ...FONT_REGULAR,
    marginTop: rs(3),
  },
});

export default Stories;
