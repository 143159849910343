import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { rs, scaleFont } from 'shared/lib/mixins';

const NoPosts = () => {
  return (
    <View style={styles.root}>
      <Text style={styles.text}>Пока нет публикаций</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: rs(400),
  },
  text: {
    fontSize: scaleFont(25),
    textAlign: 'center',
  },
});

export default NoPosts;