import { Dimensions, PixelRatio } from 'react-native';

export const fullWidth = () => {
  return Dimensions.get('window').width;
};

export const fullHeight = () => {
  return Dimensions.get('window').height;
};

export const scaleFont = (size) => size * PixelRatio.getFontScale();

export const wp = (widthPercent) => {
  const elemWidth =
    typeof widthPercent === 'number' ? widthPercent : parseFloat(widthPercent);
  return PixelRatio.roundToNearestPixel(
    (Dimensions.get('window').width * elemWidth) / 100,
  );
};

export const hp = (heightPercent) => {
  const elemHeight =
    typeof heightPercent === 'number' ? heightPercent : parseFloat(heightPercent);
  return PixelRatio.roundToNearestPixel(
    (Dimensions.get('window').height * elemHeight) / 100,
  );
};

export const rs = (size, direction = 'w', baseWidth = 375, baseHeight = 812) => {
  if (direction === 'w') {
    return wp((size / baseWidth) * 100);
  } else if (direction === 'h') {
    return hp((size / baseHeight) * 100);
  }
};

export const circleRadius = () => {
  return Math.round(fullWidth() + fullHeight()) / 2;
};
