import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import Post from '../../shared/components/Post/Post';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';import { Feather } from '@expo/vector-icons';
import { rs } from 'shared/lib/mixins';


const PostViewScreen = ({ route }) => {
  const navigation = useNavigation();
  const { post } = route.params;


  return (
    <SafeAreaView style={styles.container} edges={['top']}>
      <View style={[styles.header]}>
        <TouchableOpacity onPress={navigation.goBack}>
          <Feather name="arrow-left" size={24} color="black" />
        </TouchableOpacity>
        <Text style={styles.title}>{`Публикация от @${post.username}`}</Text>
      </View>
      <Post navigation={navigation} post={post} />
    </SafeAreaView>
  );
};

export default PostViewScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
  },
  header: {
    backgroundColor: "#ffffff",
    height: rs(40),
    justifyContent: 'center',
    paddingHorizontal: rs(8),
  },
  title: {
    position: 'absolute',
    alignSelf: 'center',
    fontWeight: '500',
  },
});
