import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { FeedScreen } from 'screens/Feed';
import ChatsNavigation from 'app/navigation/ChatsNavigation';
import { ProfileScreen } from 'screens/Profile';

const Stack = createStackNavigator();
const FeedNavigation = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name="FeedScreen"
      component={FeedScreen}
    />
    <Stack.Screen
      name='ChatsScreen'
      component={ChatsNavigation}
    />
    <Stack.Screen
      name='ProfileScreen'
      component={ProfileScreen}
    />
  </Stack.Navigator>
);

export default FeedNavigation;
