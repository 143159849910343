import React from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import { rs, scaleFont } from 'shared/lib/mixins';

const Soon = () => {
  return <SafeAreaView style={styles.root}><Text style={styles.text}>Скоро!</Text></SafeAreaView>;
};

export default Soon;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#000',
    fontSize: scaleFont(30),
  },
});