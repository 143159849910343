import { Image, StyleSheet, View } from 'react-native';
import Logo from 'assets/images/logo.png';

const Loader = () => {
  return (
    <View style={styles.root}>
      <Image source={Logo} style={{ width: 150, height: 150 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
});

export default Loader;
