import React, { memo } from 'react';
import { FlatList, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { rs } from 'shared/lib/mixins';
import { useNavigation } from '@react-navigation/native';
import NoPosts from 'components/Feed/Posts/NoPosts';

const Content = ({ data }) => {
  const navigation = useNavigation();
  const items = [
    { id: 1, imageUrl: 'https://i.pinimg.com/474x/24/2e/17/242e176835ee26d3dca144a71fa7e91b.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 2, imageUrl: 'https://i.pinimg.com/474x/7f/59/ba/7f59ba1f3879e558d338023d8c6688ec.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 3, imageUrl: 'https://i.pinimg.com/474x/7d/1f/e7/7d1fe7e45813e73b09265e254fe51a1b.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 4, imageUrl: 'https://i.pinimg.com/474x/6b/54/99/6b54993dfaa9ef8c3b8dfd8ee5ef4206.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 5, imageUrl: 'https://i.pinimg.com/474x/f9/90/0d/f9900db3c4f30ee7629518412d918263.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 6, imageUrl: 'https://i.pinimg.com/474x/98/1e/e8/981ee850c03f18b68d3a0b411b494d07.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 7, imageUrl: 'https://i.pinimg.com/474x/a9/f0/f8/a9f0f8b29ac1381a282778f5dd3ebe16.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 8, imageUrl: 'https://i.pinimg.com/564x/51/e7/65/51e765ffe7787b25fb9f33e94d38e571.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 9, imageUrl: 'https://i.pinimg.com/474x/4f/3e/2c/4f3e2c2deec5a011088a8c473a4edbdc.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 10, imageUrl: 'https://i.pinimg.com/474x/b0/5e/e4/b05ee4a95d85bb8517f94c89ba9cea54.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 11, imageUrl: 'https://i.pinimg.com/474x/ee/a5/53/eea553f5c6c56ba349d8c0e7d11e57b4.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 12, imageUrl: 'https://i.pinimg.com/474x/57/c1/8d/57c18d4d825179203dc2eb3c58165520.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 13, imageUrl: 'https://i.pinimg.com/474x/94/39/f9/9439f9ad168c6ee3d831ad15d795a1c2.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 14, imageUrl: 'https://i.pinimg.com/474x/e5/e2/7c/e5e27cb09413f9217187f604b3f59023.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
    { id: 15, imageUrl: 'https://i.pinimg.com/474x/16/ed/ff/16edfff4cfc69f8c58054793e2947aa0.jpg', likes: [], username: ['kot'], createdAt: { seconds: 86400999 } },
  ];

  const renderItem = ({ item }) => {
    //const style = [styles.itemContainer, items.length - index <= 3 && { paddingBottom: 0 }];
    return (
      <TouchableOpacity style={styles.itemContainer} onPress={() => navigation.navigate('PostViewScreen', { post: item })}>
        <Image
          style={styles.image}
          source={{ uri: item.imageUrl }} />
      </TouchableOpacity>
    );
  };


  return (
    <FlatList
      data={data.posts}
      renderItem={renderItem}
      numColumns={3}
      keyExtractor={(item, index) => index.toString()}
      ListEmptyComponent={<NoPosts />}
      style={styles.container}
    />

  );
};

export default Content;

const styles = StyleSheet.create({
  container: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // flexGrow: 3,
    // justifyContent: 'space-between',
    flex: 1,
    marginTop: rs(15),
    marginHorizontal: rs(3),
    borderRadius: 15,
    // overflow: 'hidden',
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
    margin: 2,
  },
  image: {
    width: '100%',
    height: 130,
  },
});
