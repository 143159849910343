import { Image, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { circleRadius, rs } from 'shared/lib/mixins';
import { FONT_MEDIUM, FONT_REGULAR } from 'shared/lib/typography';

const User = ({ data, navigation }) => {

  return (
    <TouchableOpacity style={styles.root} onPress={() => navigation.navigate('ProfileScreen', { uid: data.id })}>
      <Image
        style={styles.image}
        source={{
          uri: data.avatarUrl,
        }}
      />
      <View style={styles.usernames}>
        <Text>{data.name}</Text>
        <Text style={styles.username}>@{data.username}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    width: '100%',
    padding: rs(15),
    alignItems: 'center',
  },
  image: {
    width: rs(40),
    height: rs(40),
    borderRadius: circleRadius(),
  },
  usernames: {
    marginLeft: rs(15),
  },
  name: {
    ...FONT_MEDIUM,
  },
  username: {
    color: '#9797BD',
    ...FONT_REGULAR,
    marginTop: rs(5),
  },
});

export default User;
