import { StackActions, useNavigation } from '@react-navigation/native';
import React from "react";
import { Image, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { rs } from "shared/lib/mixins";
import { Container } from 'components/auth';
import { Button } from 'components';

const AuthPopup = () => {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <Button
        title="Войти с Facebook"
        onPress={() => navigation.navigate('ByFacebook')}
        style={styles.facebookButton}
      />

      <Button
        title="Войти с email"
        onPress={() => navigation.navigate('LoginScreen')}
      />

      <View style={styles.toSignUp}>
        <Text>Еще не с нами?&nbsp;</Text>
        <TouchableOpacity onPress={() => navigation.navigate('SignUpScreen')}>
          <Text style={styles.toSignUpText}>Создать аккаунт</Text>
        </TouchableOpacity>
      </View>

      <Image source={require('assets/images/logo.png')} style={styles.logo}/>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    paddingTop: rs(50),
    bottom: 0,
    width: "100%",
    height: rs(380),
    backgroundColor: "#FFF",
    borderTopLeftRadius: rs(20),
    borderTopRightRadius: rs(20),
    paddingHorizontal: 35,

    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -8,
    },
    shadowOpacity: 0.7,
    shadowRadius: 11.14,

    elevation: 17,
  },
  facebookButton: {
    backgroundColor: "#3B5999",
    borderRadius: rs(14),
    marginBottom: rs(20),
  },
  toSignUp: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 60,
  },
  toSignUpText: {
    color: '#43A2FA',
  },
  logo: {
    width: 40,
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 40,
  },
});
export default AuthPopup;
