import React from 'react';
import { SafeAreaView, StyleSheet, Text } from 'react-native';
import { rs } from 'shared/lib/mixins';
import { FONT_REGULAR } from 'shared/lib/typography';

const Maintenance = () => {
  return (
    <SafeAreaView style={styles.root}>
      <Text style={styles.smile}>¯\_(ツ)_/¯</Text>
      <Text style={styles.text}>К сожалению, мы вынуждены приостановить работу веб-версии Lagooon для исправления
        технических
        неполадок. Stay tuned! Следите за новостями на нашем сайте: lagooon.ru и в соцсетях.</Text>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  smile: {
    fontSize: rs(40),
  },
  text: {
    ...FONT_REGULAR,
    fontSize: rs(25),
    paddingHorizontal: rs(15),
    marginTop: rs(50),
    lineHeight: rs(35),
  },
});

export default Maintenance;