import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import {
  Alert,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Container } from 'components/Auth';
import { Button, KeyboardAware, TextInput } from 'components/index';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from 'shared/api/Auth/firebase';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { Auth } from 'shared/lib/errors/auth/firebase';

const SignUpScreen = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ name: '', email: '', username: '', password: '' });

  const navigation = useNavigation();

  const onSignUp = async () => {
    try {
      const { email, name, password, username } = form;
      setLoading(true);
      const userRef = await collection(db, 'users');
      const usernameQ = query(userRef, where('username', '==', username));
      const emailQ = query(userRef, where('email', '==', email));
      const usernameQSnapshot = await getDocs(usernameQ);
      const emailQSnapshot = await getDocs(emailQ);
      let emails = [];
      emailQSnapshot.forEach((doc) => {
        const data = doc.data();
        emails.push({ id: doc.id, ...data });
      });
      if (emails.length) {
        return Alert.alert(
          'Ошибка регистрации',
          'Пользователь с таким email уже зарегистрирован',
          [
            {
              text: 'Назад',
              //onPress: () => BackHandler.exitApp(),
              style: 'cancel',
            },
          ],
          { cancelable: false },
        );
      }
      let usernames = [];
      usernameQSnapshot.forEach((doc) => {
        const data = doc.data();
        usernames.push({ id: doc.id, ...data });
      });
      if (usernames.length) {
        return Alert.alert(
          'Ошибка регистрации',
          'Пользователь с таким именем пользователя уже зарегистрирован',
          [
            {
              text: 'Назад',
              //onPress: () => BackHandler.exitApp(),
              style: 'cancel',
            },
          ],
          { cancelable: false },
        );
      }
      //console.log(querySnapshot1);
      const data = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, 'users', auth.currentUser.uid), {
        avatarUrl: null,
        bio: null,
        email,
        following: [],
        imported: false,
        name,
        username,
      });
      navigation.navigate('ImportDataScreen');
    } catch (error) {
      console.log(error.code);
      Alert.alert(Auth[error.code]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container title='Регистрация в Lagooon'>
      <TextInput
        placeholder='Ваше имя'
        textContentType='name'
        value={form.name}
        onChangeText={name => {
          setForm({ ...form, name });
        }}
      />
      <TextInput
        keyboardType='email-address'
        autoCapitalize='none'
        autoCorrect={false}
        placeholder='E-mail'
        textContentType='emailAddress'
        value={form.email}
        onChangeText={email => {
          setForm({ ...form, email });
        }}
      />
      <TextInput
        autoCapitalize='none'
        autoCorrect={false}
        placeholder='Имя пользователя'
        value={form.username}
        onChangeText={username => {
          setForm({ ...form, username });
        }}
      />
      <TextInput
        placeholder='Пароль'
        secureTextEntry={true}
        textContentType='password'
        value={form.password}
        onChangeText={password => {
          setForm({ ...form, password });
        }}
      />

      <Button
        title='Регистрация'
        onPress={onSignUp}
        loading={loading}
      />

      <View style={styles.toSignIn}>
        <Text>Есть аккаунт?&nbsp;</Text>
        <TouchableOpacity
          disabled={loading}
          onPress={() => {
            navigation.navigate('LoginScreen');
          }}
        >
          <Text style={styles.toSignInText}>Войти</Text>
        </TouchableOpacity>
      </View>
    </Container>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  toSignIn: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 60,
  },
  toSignInText: {
    color: '#43A2FA',
  },
});

export default SignUpScreen;
