import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import {
  Alert,
  StyleSheet,
  Text,
  TouchableOpacity, View,
} from 'react-native';
import { TextInput, Button } from 'components';
import { Container } from 'components/Auth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'shared/api/Auth/firebase';

const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email: '', password: '' });

  const navigation = useNavigation();

  const onSignIn = async () => {
    try {
      const { email, password } = form;
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      Alert.alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container title="Приветствуем в lagooon 👋">
      <TextInput
        keyboardType='email-address'
        autoCapitalize='none'
        autoCorrect={false}
        placeholder='Имя пользователя или E-mail'
        textContentType='emailAddress'
        value={form.email}
        onChangeText={email => {
          setForm({ ...form, email });
        }}
      />
      <TextInput
        placeholder='Пароль'
        secureTextEntry={true}
        textContentType='password'
        value={form.password}
        onChangeText={password => {
          setForm({ ...form, password });
        }}
      />
      <Button
        title="Войти"
        onPress={onSignIn}
        loading={loading}
      />


      <View style={styles.toSignUp}>
        <Text>Еще не с нами?&nbsp;</Text>
        <TouchableOpacity
          disabled={loading}
          onPress={() => {
            navigation.dispatch(StackActions.replace('SignUpScreen'))
          }}
        >
          <Text style={styles.toSignUpText}>Создать аккаунт</Text>
        </TouchableOpacity>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  toSignUp: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 60,
  },
  toSignUpText: {
    color: '#43A2FA',
  },
});

export default LoginScreen;
