import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { circleRadius, rs } from 'shared/lib/mixins';
import { FONT_REGULAR } from 'shared/lib/typography';
import { timeAgo } from 'shared/lib/Dates';

const PostHeader = ({ data, navigation }) => {
  const timeAgoStr = timeAgo(data.createdAt.seconds);
  return (
    <TouchableOpacity onPress={() => navigation.navigate('ProfileScreen', {uid: data.user})} style={styles.container}>
      <View style={styles.avatar}><Text style={styles.letter}>{data.username[0].toUpperCase()}</Text></View>
      <View style={styles.info}><Text style={styles.username}>@{data.username}</Text>
        <Text
          style={styles.time}>{timeAgoStr}</Text></View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: rs(12),
  },
  avatar: {
    justifyContent: 'center',
    alignItems: 'center',
    width: rs(36),
    height: rs(36),
    borderRadius: circleRadius(),
    borderWidth: rs(1.5),
    borderColor: '#9797BD',
  },
  letter: {
    color: '#9797BD',
    fontSize: rs(16),
  },
  info: {
    marginLeft: rs(12),
    marginTop: rs(5),
  },
  username: {
    ...FONT_REGULAR,
  },
  time: {
    color: '#9797BD',
    fontSize: rs(10),
    marginTop: rs(5),
  },
});

export default PostHeader;