import React from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView, StatusBar,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { auth } from 'shared/api/Auth/firebase';
import { circleRadius, rs } from 'shared/lib/mixins';
import User from 'components/Search/components/User';
import useSearch from 'components/Search/hooks/useSearch';
import Posts from 'components/Search/components/Posts';
import { useNavigation } from '@react-navigation/native';

const SearchScreen = () => {
  const { posts, result, setQuery, query } = useSearch();
  const navigation = useNavigation();

  return (
    <SafeAreaView style={styles.root}>
      <ScrollView>
        <Text style={styles.title}>Поиск</Text>
        <TextInput
          //keyboardType={keyboardType}
          autoCapitalize='none'
          autoCorrect={false}
          onChangeText={setQuery}
          placeholder='Поиск'
          style={styles.input}
          placeholderTextColor={'#9797BD'}
          //textContentType={textContentType}
          //value={value}
        />
        {result ? result.map((item) => (
          <User data={item} navigation={navigation} key={item.username} />
        )) : <Posts data={posts} />}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#ffffff',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  title: {
    fontSize: rs(17),
    marginVertical: rs(15),
    textAlign: 'center',
  },
  input: {
    backgroundColor: 'rgba(151, 151, 189, 0.15)',
    borderRadius: circleRadius(),
    paddingHorizontal: rs(13),
    paddingVertical: rs(11),
    marginHorizontal: rs(17),
  },
});
export default SearchScreen;
