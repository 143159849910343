import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from './shared/hooks/useCachedResources';
import useColorScheme from 'shared/hooks/useColorScheme';
import AuthNavigation from 'app/navigation/AuthNavigation';
import { useFonts } from 'expo-font';
import Loader from 'shared/ui/Loader';
import useInit from 'shared/hooks/useInit';
import Maintenance from 'widgets/Maintenance';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  let [fontsLoaded] = useFonts({
    'HelveticaNeueCyr-Bold': require('./assets/fonts/HelveticaNeueCyr-Bold.ttf'),
    'HelveticaNeueCyr-Light': require('./assets/fonts/HelveticaNeueCyr-Light.ttf'),
    'HelveticaNeueCyr-Medium': require('./assets/fonts/HelveticaNeueCyr-Medium.ttf'),
    'HelveticaNeueCyr-Roman': require('./assets/fonts/HelveticaNeueCyr-Roman.ttf'),
  });

  const isAllowed = useInit();

  if (true) {
    return <Maintenance />;
  }

  if (!isLoadingComplete || !fontsLoaded || !isAllowed) {
    return <Loader />;
  } else {
    return (
      <SafeAreaProvider>
        {/* <Navigation colorScheme={colorScheme} /> */}
        <AuthNavigation />
        {/* <StatusBar /> */}
      </SafeAreaProvider>
    );
  }
}
