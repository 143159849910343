import Posts from "components/Feed/Posts";
import Stories from "components/Feed/Stories";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View, FlatList, Platform, StatusBar } from 'react-native';
import { ScrollView } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { auth } from "shared/api/Auth/firebase";
import Header from "../../components/Feed/Header";
import NoPosts from 'components/Feed/Posts/NoPosts';
import usePosts from 'components/Feed/Posts/hooks/usePosts';
import Post from 'shared/components/Post/Post';
import { useNavigation } from '@react-navigation/native';

const FeedScreen = () => {
  const posts = usePosts();
  const navigation = useNavigation();

  return (
    <SafeAreaView style={styles.root} edges={['top']}>
      <Header />
      <FlatList
        ListHeaderComponent={() => <Stories />}
        data={posts}
        showsVerticalScrollIndicator={false}
        keyExtractor={item => `key_${item.id}`}
        renderItem={({ item }) => <Post navigation={navigation} post={item} />}
        ListEmptyComponent={<NoPosts />}
        //getItemCount={() => posts.length}
        // getItem={() => posts}
      />
    </SafeAreaView>
  );



  /*return (
    <SafeAreaView style={styles.root}>
      <ScrollView>
        <View>
          <Header />
          <Stories />

          <FlatList
            style={{ borderWidth: 1 }}
            data={posts}
            showsVerticalScrollIndicator={false}
            initialNumToRender={posts.length}
            keyExtractor={item => `key_${item.id}`}
            renderItem={({ item }) => <Post data={item} />}
            ListEmptyComponent={<NoPosts />}
            //getItemCount={() => posts.length}
            // getItem={() => posts}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );*/
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "#ffffff",
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
});

export default FeedScreen;
