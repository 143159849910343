import React from 'react';
import { Text, StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import { rs } from 'shared/lib/mixins';

export enum ButtonTypes {
  TYPE_DEFAULT = 'default',
  TYPE_PRIMARY = 'primary',
  TYPE_SUCCESS = 'success',
  TYPE_DANGER = 'danger',
}

export interface ButtonProps extends ViewProps {
  title: string,
  loading?: boolean,
  disabled?: boolean,
  onPress?: any,
  style?: Object,
  type?: ButtonTypes,
}

const Button = ({ title, loading = false, disabled = false, onPress, style: styleProp }: ButtonProps) => {
  return (
    <TouchableOpacity
      style={[
        style.container,
        { opacity: (disabled || loading) ? .5 : 1 },
        styleProp,
      ]}
      onPress={onPress}
      activeOpacity={.8}
      disabled={disabled || loading}
    >
      <Text style={[style.title]}>
        { title }
      </Text>
    </TouchableOpacity>
  );
};

const style = StyleSheet.create({
  container: {
    backgroundColor: '#262628',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: rs(14),
    height: rs(60),
  },

  title: {
    fontWeight: '500',
    fontSize: rs(14),
    color: '#ffffff',
  },
});

export default Button;
