import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';
import FeedNavigation from './FeedNavigation';
import SearchNavigation from './SearchNavigation';
import PublishNavigation from './PublishNavigation';
import ActivityNavigation from './ActivityNavigation';
import ProfileNavigation from './ProfileNavigation';
import Icon from 'shared/ui/Icon';
import { StyleSheet } from 'react-native';
import { rs } from 'shared/lib/mixins';
import ChatsNavigation from './ChatsNavigation';
import { AuthScreen, LoginScreen, SignUpScreen } from 'screens/Auth';
import ByFacebook from 'components/Auth/LoginByFacebook';
import Soon from 'widgets/Soon';

type RootTabParamList = {
  FeedTab: undefined;
  SearchTab: undefined;
  PublishTab: undefined;
  ActivityTab: undefined;
  ProfileTab: undefined;
};

const SignedInNavigation = () => {
  const Root = createBottomTabNavigator<RootTabParamList>();

  const screenOptions = {};

  return (
    <NavigationContainer>
      <Root.Navigator
        initialRouteName='FeedTab'
        tabBarOptions={{
          showLabel: false,
          keyboardHidesTabBar: true,
        }}
      >
        <Root.Screen
          name='FeedTab'
          component={FeedNavigation}
          options={({ route }) => {
            return {
              header: () => false,
              tabBarVisible: true,
              tabBarIcon: params => <Icon name='home' style={styles.icon} width={28} height={28} />,
            };
          }}
        />
        <Root.Screen
          name='SearchTab'
          component={SearchNavigation}
          options={({ route }) => {
            return {
              header: () => false,
              tabBarVisible: true,
              tabBarIcon: params => <Icon name='search' style={styles.icon} width={28} height={28} />,
            };
          }}
        />
        <Root.Screen
          name='PublishTab'
          component={PublishNavigation}
          options={({ route }) => {
            return {
              headerTitle: 'Публикация',
              tabBarVisible: true,
              tabBarIcon: params => <Icon name='plus' style={styles.icon} width={28} height={28} />,
            };
          }}
        />
        <Root.Screen
          name='ActivityTab'
          component={ActivityNavigation}
          options={({ route }) => {
            return {
              headerTitle: 'Активность',
              tabBarVisible: true,
              tabBarIcon: params => <Icon name='like' style={styles.icon} width={28} height={28} />,
            };
          }}
        />

        <Root.Screen
          name='ProfileTab'
          component={ProfileNavigation}
          options={({ route }) => {
            return {
              headerTitle: 'Профиль',
              header: () => false,
              tabBarVisible: true,
              tabBarIcon: params => <Icon name='profile' style={styles.icon} width={30} height={30} />,
            };
          }}
        />
      </Root.Navigator>
    </NavigationContainer>
  );

  /*return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Profile">
        {/!*<Stack.Screen name="Feed" component={Feed} options={screenOptions} />*!/}
        <Stack.Screen name="Comments" component={Comments} options={screenOptions} />
        <Stack.Screen name="Publish" component={Publish} options={screenOptions} />
        <Stack.Screen name="Activity" component={Activity} options={screenOptions} />
      </Stack.Navigator>
    </NavigationContainer>
  );*/
};

export default SignedInNavigation;

const styles = StyleSheet.create({
  icon: {
    marginHorizontal: rs(22),
  },
});
