import React from 'react';
import { Image, Platform, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, View } from 'react-native';
import { fullWidth, rs } from 'shared/lib/mixins';
import Header from 'components/Profile/components/Header';
import useProfile from 'components/Profile/hooks/useProfile';
import Content from 'components/Profile/components/Content';
import usePosts from 'components/Profile/hooks/usePosts';
import { auth } from 'shared/api/Auth/firebase';

const ProfileScreen = ({ route }) => {
  const uid = route.params?.uid || auth.currentUser.uid;
  const userData = useProfile(uid);
  const posts = usePosts(uid);
  const data = { ...userData, posts, uid };

  if (data) {
    return (
      <SafeAreaView style={styles.container}>
        {/*<Image source={{ uri: data.avatarUrl }} style={styles.backgroundImage} />*/}

        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.content}>
            <Header data={data} />
            <Content data={data} />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  } else {
    return <></>;
  }
};

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
    flex: 1,
    backgroundColor: '#F6F7FB',
  },
  backgroundImage: {
    width: fullWidth(),
    height: 200,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  content: {
    //marginTop: 180,
    marginTop: rs(70),
    zIndex: 200,
    backgroundColor: '#F6F7FB',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
});


export default ProfileScreen;
