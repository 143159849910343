import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { auth } from 'shared/api/Auth/firebase';
import Logo from 'shared/assets/icons/Feed/logo.png';
import { rs } from 'shared/lib/mixins';
import Icon from 'shared/ui/Icon';

const Header = () => {
  const navigation = useNavigation();
  return (
    <View style={styles.root}>
      <Image source={Logo} style={{ width: 40, height: 40 }} />
      {/*<TouchableOpacity onPress={() => auth.signOut()}>*/}
      {/*  <Text style={{ color: '#000' }}>Header</Text>*/}
      {/*</TouchableOpacity>*/}
      <View style={styles.icons}>
        <TouchableOpacity onPress={() => navigation.navigate('SearchTab')}>
          <Icon name='search' style={styles.icon} width={rs(26)} height={rs(26)} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('ActivityTab')}>
          <Icon name='like' style={styles.icon} width={rs(25)} height={rs(25)} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('ChatsScreen')}>
          <Icon name='message' style={styles.icon} width={rs(26)} height={rs(26)} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginHorizontal: rs(15),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: rs(10),
  },
  icons: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginLeft: rs(15),
    width: rs(26),
    height: rs(26),
  },
});

export default Header;
