import React from 'react';
import { FlatList, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { rs } from 'shared/lib/mixins';
import NoPosts from 'components/Feed/Posts/NoPosts';
import { useNavigation } from '@react-navigation/native';

const Posts = ({ data }) => {
  const navigation = useNavigation();

  const renderItem = ({ item }) => {
    //const style = [styles.itemContainer, items.length - index <= 3 && { paddingBottom: 0 }];
    return (
      <TouchableOpacity style={styles.itemContainer} onPress={() => navigation.navigate('PostViewScreen', { post: item })}>
        <Image
          style={styles.image}
          source={{ uri: item.imageUrl }} />
      </TouchableOpacity>
    );
  };


  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      numColumns={3}
      keyExtractor={(item, index) => index.toString()}
      ListEmptyComponent={<NoPosts />}
      style={styles.container}
    />

  );
};

export default Posts;

const styles = StyleSheet.create({
  container: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // flexGrow: 3,
    // justifyContent: 'space-between',
    flex: 1,
    marginTop: rs(15),
    marginHorizontal: rs(3),
    borderRadius: 15,
    // overflow: 'hidden',
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
    margin: 2,
  },
  image: {
    width: '100%',
    height: 130,
  },
});
