import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import Logo from 'assets/images/splash.png';
import { rs } from 'shared/lib/mixins';

const EntryLoader = () => {
  return (
    <View style={styles.root}>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    marginHorizontal: rs(15),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export default EntryLoader;