import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { SearchScreen } from 'screens/Search';
import { PostViewScreen, ProfileScreen } from 'screens/Profile';

const Stack = createStackNavigator();
const SearchNavigation = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name="SearchScreen"
      component={SearchScreen}
    />
    <Stack.Screen
      name='PostViewScreen'
      component={PostViewScreen}
    />
    <Stack.Screen
      name='ProfileScreen'
      component={ProfileScreen}
    />
  </Stack.Navigator>
);

export default SearchNavigation;
