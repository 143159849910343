import React from 'react';
import {
  View,
  ViewProps,
  StyleSheet,
  Image,
  SafeAreaView,
  TouchableOpacity,
  Text,
  KeyboardAvoidingView, Platform,
} from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { KeyboardAware } from 'components';
import { rs } from 'shared/lib/mixins';
import { useNavigation } from '@react-navigation/native';
import { FONT_BOLD } from 'shared/lib/typography';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface ContainerProps extends ViewProps {
  title?: string;
}

const Container = ({ title, children }: ContainerProps) => {
  const navigation = useNavigation();

  const { bottom } = useSafeAreaInsets();

  return (
    <View style={{ flex: 1 }}>
      <Image
        source={require('shared/assets/images/Auth/hero.jpg')}
        style={styles.bg}
      />
      <View style={styles.backdrop} />
      <View style={[styles.content, { paddingBottom: bottom + rs(16) }]}>
        <TouchableOpacity style={styles.backBtn} onPress={navigation.goBack}>
          <Entypo name='chevron-left' size={24} color='#262628' />
        </TouchableOpacity>
        {!!title && <Text style={styles.title}>{title}</Text>}
        <KeyboardAware extraHeight={Platform.OS === 'ios' ? rs(405) : rs(50)}>
          {children}
          <Image source={require('assets/images/logo.png')} style={styles.logo} />
        </KeyboardAware>

      </View>
    </View>
  )
    ;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  bg: {
    width: '100%',
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000',
    opacity: .3,
  },
  backBtn: {
    width: 36,
    height: 36,
    borderWidth: 2,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#9797BD',
    marginLeft: rs(-24),
    marginTop: rs(15),
  },
  content: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 150,
    borderWidth: 1,
    padding: rs(35),
    paddingTop: 0,
    paddingBottom: rs(16),
    backgroundColor: '#FFF',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -8,
    },
    shadowOpacity: 0.7,
    shadowRadius: 11.14,

    elevation: 17,
  },
  title: {
    ...FONT_BOLD,
    color: '#262628',
    fontSize: rs(36),
    marginBottom: rs(44),
    textAlign: 'center',
    fontWeight: '900',
  },
  logo: {
    width: rs(40),
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 40,
  },
});

export default Container;
