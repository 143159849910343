import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import Soon from 'widgets/Soon';

const Stack = createStackNavigator();
const PublishNavigation = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name="PublishScreen"
      component={Soon}
    />

  </Stack.Navigator>
);

export default PublishNavigation;
