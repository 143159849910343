import { View, Text, Alert, BackHandler } from 'react-native';
import React, { useEffect, useState } from 'react';
import { $data } from 'shared/api';

const useInit = () => {
  const [isAllowed, setAllowed] = useState(false);
  // const updateLinkFinder = () => {
  //
  // }
  //
  const showPopup = (description, isAllowed, title) => {
    Alert.alert(
      title,
      description,
      [
        { text: 'OK', onPress: () => isAllowed ? setAllowed(true) : showPopup(description, isAllowed, title) },
      ],
      { cancelable: isAllowed },
    );
  };

  useEffect(() => {
    //showPopup();
    (async () => {
      // const { data } = await $data.post('/checkversion', { version: '0.0.1' });
      // const { description, isActual, isAllowed, title, success } = data;
      // if (!isActual) {
      //   return showPopup(description, isAllowed, title);
      // }
      setAllowed(true);
    })();
  }, []);
  return isAllowed;
};

export default useInit;
