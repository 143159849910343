import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { PostViewScreen, ProfileScreen } from 'screens/Profile';
import Soon from 'widgets/Soon';
import ChatsNavigation from 'app/navigation/ChatsNavigation';

const Stack = createStackNavigator();
const ProfileNavigation = () => (
  <Stack.Navigator initialRouteName='ProfileScreen' screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name='ProfileScreen'
      component={ProfileScreen}
    />
    <Stack.Screen
      name='ChatsScreen'
      component={ChatsNavigation}
    />
    <Stack.Screen
      name='PostViewScreen'
      component={PostViewScreen}
    />
  </Stack.Navigator>
);

export default ProfileNavigation;
