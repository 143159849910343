import React, { useEffect } from 'react';
import { Button, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Icon from 'shared/ui/Icon';
import { circleRadius, rs } from 'shared/lib/mixins';
import { counterToString } from 'shared/lib/counters';
import { useNavigation } from '@react-navigation/native';
import { auth } from 'shared/api/Auth/firebase';
import { useProfileAction } from '../hooks/useProfileAction';

const Header = ({ data }) => {
  const navigation = useNavigation();
  const following = counterToString(data?.following?.length);
  const {isSubcribe, subscribeHandler, subscribeCount} = useProfileAction(data?.following, data.uid)
  const followers = counterToString(subscribeCount);

  const myProfile = data.uid === auth.currentUser.uid

  return (
    <View>
      <View style={styles.header}>
        <View style={styles.followsContainer}>
          <Text style={styles.followsCount}>{followers}</Text>
          <Text style={styles.followsText}>Подписчики</Text>
        </View>

        <View style={styles.avatarContainer}>
          <Image source={{ uri: data.avatarUrl }} style={styles.avatar} />
        </View>

        <View style={styles.followsContainer}>
          <Text style={styles.followsCount}>{following}</Text>
          <Text style={styles.followsText}>Подписки</Text>
        </View>
      </View>

      <Text style={styles.name}>{data.name}</Text>
      {data.bio && <Text style={styles.description}>{data.bio}</Text>}
      {data.location && <View style={styles.addressContainer}>
        <Icon name='location' width={15} height={15} />
        <Text style={styles.address}>Москва/Волгоград</Text>
      </View>}

      {
        !myProfile && (
          <View style={styles.actionsContainer}>
              <TouchableOpacity style={styles.subscribeButton} onPress={subscribeHandler}>
                <Text style={styles.subscribeText}>{isSubcribe ? 'Отписаться' : 'Подписаться'}</Text>
              </TouchableOpacity>
            <TouchableOpacity style={styles.messageButton} onPress={() => navigation.navigate('ChatsScreen')}>
            <Text>Сообщение</Text>
            </TouchableOpacity>
          </View>
        )
      }

      
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  followsContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
  },
  followsText: {
    color: '#9797BD',
    fontSize: 15,
    fontWeight: '500',
  },
  followsCount: {
    color: '#262628',
    fontWeight: '700',
    fontSize: 24,
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 1000,
  },
  avatarContainer: {
    borderWidth: 8,
    borderColor: 'white',
    borderRadius: 1000,
    marginTop: -70,
    aspectRatio: 1,
  },
  name: {
    fontWeight: '700',
    fontSize: 21,
    textAlign: 'center',
    marginTop: 15,
  },
  description: {
    color: '#9797BD',
    textAlign: 'center',
    marginTop: 5,
  },
  addressContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  address: {
    color: '#9797BD',
  },
  actionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginTop: rs(20),
  },
  subscribeButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: rs(165),
    height: rs(45),
    backgroundColor: '#262628',
    borderRadius: circleRadius(),
  },
  subscribeText: {
    color: '#fff',
  },
  messageButton: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#000',
    width: rs(165),
    height: rs(45),
    borderRadius: circleRadius(),
    borderWidth: 1,
  },
});


export default Header;