import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { ActivityScreen } from 'screens/Activity';
import Soon from 'widgets/Soon';

const Stack = createStackNavigator();
const ActivityNavigation = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name='ActivityScreen'
      //component={ActivityScreen}
      component={Soon}
    />

  </Stack.Navigator>
);

export default ActivityNavigation;
