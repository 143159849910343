import React from 'react';
import { Image, StyleSheet, View, Dimensions, Text, TouchableOpacity } from 'react-native';
import { Feather, Entypo, FontAwesome } from '@expo/vector-icons';
import { rs, scaleFont } from 'shared/lib/mixins';
import Icon from 'shared/ui/Icon';
import { secondaryText } from 'constants/Colors';
import PostHeader from 'shared/components/Post/components/PostHeader';
import { useLike } from 'shared/hooks/useLike';

const { width: deviceWidth } = Dimensions.get('window');
const imageSize = deviceWidth - (rs(8) * 2);

export interface PostModel {
  id: string;
  username: string;
  user: string;
  createdAt: {
    seconds: number;
  };
  comments: [];
  caption?: string;
  likes: string[];
  imageUrl: string;
}

interface PostProps {
  navigation: any;
  post: PostModel;
}

const Post = ({ navigation, post }: PostProps) => {
  const {handleLike, countLikes, isLiked} = useLike(post.likes, post.id)
  // console.log(post)

  return (
    <View style={styles.container}>
      <PostHeader data={post} navigation={navigation}/>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            { width: imageSize, height: imageSize },
          ]}
          source={{ uri: post.imageUrl }}
        />

        <View style={styles.actions}>
          {/*<TouchableOpacity style={styles.action}>*/}
          {/*  <Feather name='bookmark' size={20} color='black' />*/}
          {/*</TouchableOpacity>*/}
          <TouchableOpacity style={styles.action} onPress={handleLike}>
            <Feather name='heart' size={20} color={isLiked ? 'red' : 'black'} />
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.infoContainer}>
        <View style={styles.userLikeWrap}>
          {/*{*/}
          {/*  Array.from(Array(3)).map(() => (*/}
          {/*    <View style={styles.userLike}>*/}
          {/*      <Text style={styles.userLikeTxt}>A</Text>*/}
          {/*    </View>*/}
          {/*  ))*/}
          {/*}*/}
        </View>
        {countLikes > 0 ? <Text
            style={styles.likeTxt}>{countLikes} {countLikes > 1 ? 'людей' : 'человек'} {countLikes > 1 ? 'оценили' : 'оценил'}</Text> :
          <Text
            style={styles.likeTxt}>Никто ещё не оценил запись</Text>}
        <TouchableOpacity onPress={() => navigation.navigate('ChatsScreen')}>
          <Icon name='message' width={rs(24)} height={rs(24)} />
        </TouchableOpacity>
      </View>

      <Text style={styles.caption}>{post.caption}</Text>
    </View>
  );
};

export default Post;


const styles = StyleSheet.create({
  container: {
    marginBottom: rs(16),
    padding: rs(8),
  },
  imageContainer: {
    marginBottom: rs(12),
  },
  image: {
    borderRadius: 8,
  },
  actions: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'flex-end',
    padding: rs(10),
    flexDirection: 'row',
  },
  action: {
    backgroundColor: '#FFF',
    width: rs(30),
    height: rs(30),
    borderRadius: rs(15),
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: rs(8),
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: rs(8),
  },
  userLikeWrap: {
    flexDirection: 'row',
    //paddingLeft: rs(8),
  },
  userLike: {
    backgroundColor: '#FFF',
    borderColor: secondaryText,
    borderWidth: 1,
    width: rs(30),
    height: rs(30),
    borderRadius: rs(15),
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: rs(-8),
  },
  userLikeTxt: {
    color: secondaryText,
    fontWeight: '500',
    fontSize: scaleFont(16),
  },
  likeTxt: {
    color: secondaryText,
    fontWeight: '500',
    marginRight: 'auto',
    marginLeft: rs(8),
  },
  caption: {},
});
