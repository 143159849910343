import React from 'react';
import { StyleSheet, Text, TextInput as RNTextInput, TextInputProps as RNTextInputProps, View } from 'react-native';
import { rs } from 'shared/lib/mixins';

interface TextInputProps extends RNTextInputProps {

}

const TextInput = ({ placeholder, value, ...props }: TextInputProps) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{placeholder}</Text>
      <RNTextInput
        style={styles.input}
        {...props}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: rs(20),
  },
  label: {
    color: '#262628',
    fontWeight: '500',
    marginBottom: rs(10),
  },
  input: {
    padding: rs(18),
    borderWidth: rs(1),
    fontSize: rs(14),
    borderColor: "rgba(38,38,40,0.2)",
    borderRadius: rs(14),
  },
});
export default TextInput;
