import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import Soon from 'widgets/Soon';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { SafeAreaView } from 'react-native';
//import { ChatsScreen } from 'screens/Chats';

const Stack = createStackNavigator();

const ChatsNavigation = () => (
  <SafeAreaView style={{ flex: 1 }}>
    <Stack.Navigator screenOptions={{
      headerMode: 'screen',
      headerTintColor: 'black',
      headerTitle: 'Чаты',
    }}>
      <Stack.Screen
        name='ChatsScreen'
        component={Soon}
        options={{ title: 'Чаты' }}
      />

    </Stack.Navigator>
  </SafeAreaView>
);

export default ChatsNavigation;
